import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Swiper, SwiperSlide } from 'swiper/react'

import {
  Content,
  ContentButton,
  ContentCategories,
  ContentCategory,
  ContentDate,
  ContentTitle,
  Wrapper
} from './style'
import { Container } from '@/components/HomeHero/Item/style'
import resolveAssetURL from '@/utils/resolveAssetURL'
import { LabelIcon, LabelLink, LabelText, Overlay } from '@/components/HeroSingleJournal/style'
import longArrowLeft from '@/images/long-arrow-left.svg'
const HeroSingleJournal = ({
  image,
  title,
  backLabel,
  backCta,
  date
}) => {
  return (
    <Wrapper>
      <Container background={ resolveAssetURL(image) }>
        <Overlay>
          <Content>

            <LabelLink href={ backCta }>
              <LabelIcon src={ longArrowLeft } />
              <LabelText>{ backLabel }</LabelText>
            </LabelLink>
            <ContentTitle>{title}</ContentTitle>
            <ContentDate>{moment(date).format('LL')}</ContentDate>
          </Content>
        </Overlay>
      </Container>
    </Wrapper>
  )
}

HeroSingleJournal.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  backLabel: PropTypes.string,
  backCta: PropTypes.string,
  date: PropTypes.string
}

export default HeroSingleJournal
