import styled from '@emotion/styled'

import { mediaMax } from '@/styles/mixins'

import { StyledGridContainer } from '@/components/Grid'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;

  ${mediaMax.xs} {
    padding: 2rem 0;
  }

  ${StyledGridContainer} {
    position: relative;

    ${mediaMax.xs} {
      padding: 0;
    }
  }
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  width: 100%;
  height: 100%;

  ${mediaMax.md} {
    gap: 5rem;

  }

  ${mediaMax.xs} {
    flex-direction: column;
    gap: 3.6rem;
  }

  &.alt {
    flex-direction: row-reverse;

    ${mediaMax.xs} {
      flex-direction: column-reverse;
    }
  }
`

export const ImageContent = styled.figure`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LeftImageWrapper = styled.div`
  position: relative;
  width: 40rem;

  ${ImageContent} {
    width: 100%;
    height: 39.1rem;
    overflow: hidden;
    pointer-events: none;

    ${mediaMax.xs} {
      aspect-ratio: 1 / 1;
      width: 100%;
      height: 31.1rem;
    }
  }

  ${mediaMax.xs} {
    width: 100%;
    padding: 0 3.6rem;
  }
`

export const RightImageWrapper = styled.div`
  position: relative;
  width: 50rem;

  ${ImageContent} {
    height: 64.9rem;
    overflow: hidden;
    pointer-events: none;
  }

  ${mediaMax.xs} {
    width: 100%;

    ${ImageContent} {
      width: 100%;
      height: 50.6rem;
    }
  }
`

export const ImageLegend = styled.div`
  font-size: 1.2rem;
  letter-spacing: 0.02rem;
  max-width: 40rem;
  margin-top: 1.6rem;

  ${mediaMax.xs} {
    text-align: center;
    max-width: 100%;
    padding: 0 2rem;
  }
`
