import styled from '@emotion/styled'
import { Wrapper } from '@/components/HeroSingleJournal/style.js'
import { mediaMax } from '@/styles/mixins'

export const EventsSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing.medium};
  width: 100%;
`

export const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightVanilla3};

  & > ${Wrapper} {
    margin-bottom: 7.2rem;


    ${mediaMax.xs} {
      margin-bottom: 4rem;
    }
  }


`
