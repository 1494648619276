import styled from '@emotion/styled'

import { mediaMax } from '@/styles/mixins'
import { StyledGridContainer } from '@/components/Grid'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8rem 0;

  ${mediaMax.xs} {
    padding: 6.4rem 0;
  }

  ${StyledGridContainer}{
    position: relative;

    ${mediaMax.xs} {
      padding: 0 3.6rem;
    }
  }
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
export const TagsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  width: fit-content;

  ${mediaMax.xs} {
    gap: 1.2rem;
  }
`

export const TagButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2.4rem;
  border-radius: 2rem;
  border: 1px solid ${({ theme }) => theme.colors.green};
  cursor: pointer;
  text-decoration: none;

  ${mediaMax.xs} {
    padding: 1rem 1.6rem;
  }
`

export const TagText = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.green};
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  text-align: center;
  line-height: 0.9rem;
  text-decoration: none;
`
