import * as React from 'react'

import { wineType } from '@/model/wine'

import { Container, Content, ContentTag, ContentTitle, Image, Wrapper } from './style'

const TagsBlockSliderItem = ({ item }) => {
  return (
    <Container to={ item.path } title={ `See more about ${item.title}` }>
      <Wrapper>
        <Image loading='lazy' src={ item.url } alt={ `Card Tag: ${item.tag}` } />
      </Wrapper>
      <Content>
        <ContentTag>{item.tag}</ContentTag>
        <ContentTitle>{item.title}</ContentTitle>
      </Content>
    </Container>
  )
}

TagsBlockSliderItem.propTypes = {
  item: wineType.isRequired
}

export default TagsBlockSliderItem
