import * as React from 'react'

import { Container, TagButton, TagsWrapper, TagText, Wrapper } from './style'
import PropTypes from 'prop-types'
import { GridContainer } from '@/components/Grid'
import { useRecoilValue } from 'recoil'

import { marketCodeManager } from '@/recoil/marketCode'

const ArticleTags = ({ tags }) => {
  // const marketCode = useRecoilValue(marketCodeManager)

  return (
    <Container>
      <GridContainer>
        <Wrapper>
          <TagsWrapper>
            {tags.map((tag, index) => (
              <TagButton key={ `tag-${index}` } href={ `../?filters=${tag.id}&sort=latest` }>
                <TagText>{tag.text}</TagText>
              </TagButton>
            ))}
          </TagsWrapper>
        </Wrapper>
      </GridContainer>
    </Container>
  )
}

ArticleTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    id: PropTypes.string
  }))
}

export default ArticleTags
