import * as React from 'react'
import PropTypes from 'prop-types'

import { GridContainer } from '@/components/Grid'

import { Container, ContentNumber, ContentText, ContentWrapper, Wrapper } from './style'

const NumberContent = ({ items }) => {
  return (
    <Container>
      <GridContainer>
        <Wrapper>
          {items.map((item, index) => (
            <ContentWrapper key={ `content-${index}` }>
              <ContentNumber>{ item.number }.</ContentNumber>
              <ContentText>{ item.text }</ContentText>
            </ContentWrapper>
          ))}
        </Wrapper>
      </GridContainer>
    </Container>
  )
}

NumberContent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    number: PropTypes.number,
    text: PropTypes.string
  })).isRequired
}

export default NumberContent
