import styled from '@emotion/styled'
import { Swiper } from 'swiper/react'

import WysiwygBase from '@/components/Wysiwyg'
import { Wrapper as ArrowWrapper } from '@/components/Block/Sliders/atoms/Arrows/style'
import { Container as ContainerFilter, Wrapper as WrapperFilter } from '@/components/Forms/Inputs/Select/style'
import { StyledGridContainer as GridContainer } from '@/components/Grid'

import { mediaMax, sectionSubtitle, sectionTitle } from '@/styles/mixins'

import 'swiper/css'
import 'swiper/css/virtual'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8rem 0;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;

  ${mediaMax.xs} {
    overflow: hidden;
    padding: 0;
    padding-top: 5rem;

    ${GridContainer} {
      padding: 0;
    }
  }
`

export const Head = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 4rem;
  padding-bottom: 4rem;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.brown};
    opacity: 0.5;
  }

  ${ContainerFilter} {
    width: auto;
    margin-right: 2rem;
  }

  ${WrapperFilter} {
    justify-content: flex-end;
    padding: 1.5rem 2rem;

    &:after {
      all: unset;
    }
  }

  ${mediaMax.xs} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 1rem;
    margin-bottom: 4rem;

    ${WrapperFilter} {
      label {
        font-family: ${({ theme }) => theme.fonts.sansSerif};
        font-size: 1.2rem !important;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.brown};
        text-transform: uppercase;
        letter-spacing: 0.02rem;
      }
    }

    &:after {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      width: 90%;
      opacity: 1;
    }
  }
`

export const HeadWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

export const Title = styled.div`
  ${sectionTitle()}
  font-weight: 400;

  ${mediaMax.xs} {
    font-family: ${({ theme }) => theme.fonts.sansSerif};
    font-size: 1.2rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.brown};
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    padding-left: 2rem;
  }
`

export const Subtitle = styled.span`
  ${sectionSubtitle()}
  color: ${({ theme }) => theme.colors.lightGreen};
  margin-bottom: 2rem;
`

export const Text = styled(WysiwygBase)`
  width: 80%;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  color: ${({ theme }) => theme.colors.grey};

  ${mediaMax.xs} {
    margin-top: 4rem;
    font-size: 1.4rem;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
`

export const SwiperContent = styled(Swiper)`
  width: 100%;
  overflow: visible !important;

  .swiper-slide {
    width: 40rem;
  }

  ${mediaMax.xs} {
    padding-bottom: 4rem !important;
    overflow: hidden !important;

    .swiper-slide:first-of-type {
      padding-left: ${({ theme }) => theme.paddingX.m};
    }

    .swiper-slide:last-of-type {
      padding-right: ${({ theme }) => theme.paddingX.m};
    }
  }
`

export const ActionWrapper = styled.div`
  width: 100%;

  ${ArrowWrapper} {
    &.inverted {
      width: 100%;
      flex-direction: row;
    }
  }

  ${mediaMax.xs} {
    width: 100%;
    display: none;
  }
`

export const LabelLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  text-decoration: none;
  pointer-events: all;
  cursor: pointer;
  z-index: 2;

  ${mediaMax.xs} {
    padding: 1rem 2rem;
  }
`

export const LabelText = styled.span`
  font-size: 1.8rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.brown};

  ${mediaMax.xs} {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
  }
`

export const LabelIcon = styled.img`
  width: 3.2rem;
  height: 3.2rem;
  filter: invert(1);

  ${mediaMax.xs} {
    display: none;
  }
`
