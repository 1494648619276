import * as React from 'react'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import resolveAssetURL from '@/utils/resolveAssetURL'

import {
  CloseButton,
  Container,
  ContentTop,
  Image,
  ImageCaption,
  ImageWrapper,
  OpenButton,
  Viewer,
  ViewerImage,
  ViewerWrapper,
  Wrapper
} from './style'
import { StyledGridContainer } from '@/components/Grid'

import closeButton from '@/images/close-button.svg'
import openButton from '@/images/open-button.svg'

const ImageLandscape = ({
  image = '',
  alt = '',
  caption = '',
  isLarge = false
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const openOverlay = () => {
    setIsOpen(true)
  }

  const closeOverlay = () => {
    setIsOpen(false)
  }

  return (
    <Container>
      <StyledGridContainer>
        <Wrapper>
          <Viewer isOpen={ isOpen }>
            <ViewerWrapper>
              <ViewerImage loading='lazy' src={ resolveAssetURL(image) } alt={ alt } />
            </ViewerWrapper>
            <CloseButton onClick={ closeOverlay }>
              <Image src={ closeButton } alt='close icon' />
            </CloseButton>
          </Viewer>
          <ContentTop>
            <ImageWrapper isLarge={ isLarge }>
              <Image loading='lazy' src={ resolveAssetURL(image) } alt={ alt } />
              <OpenButton onClick={ openOverlay }>
                <Image src={ openButton } alt='open icon' />
              </OpenButton>
            </ImageWrapper>
            { !!caption && (<ImageCaption isLarge={ isLarge }>{ caption }</ImageCaption>) }
          </ContentTop>
        </Wrapper>
      </StyledGridContainer>
    </Container>
  )
}

ImageLandscape.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  caption: PropTypes.string,
  isLarge: PropTypes.bool
}

export default ImageLandscape
