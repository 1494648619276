import styled from '@emotion/styled'

import { mediaMax } from '@/styles/mixins'

import { StyledGridContainer } from '@/components/Grid'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;

  ${mediaMax.xs} {
    padding: 2rem 0;
  }

  ${StyledGridContainer}{
    position: relative;

    ${mediaMax.xs} {
      padding: 0 3.6rem;
    }
  }
`

export const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 6rem;
  column-gap: 8rem;
  width: 100%;

  ${mediaMax.md} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mediaMax.xs} {
    grid-template-columns: 1fr;
    row-gap: 4rem;
    column-gap: 0;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
`

export const ContentText = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.brown};
  line-height: 1.5em;
  letter-spacing: -0.03rem;
`

export const ContentNumber = styled.h3`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 4rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.green};
  line-height: 1.2em;
  margin-right: 2rem;
`
