import * as React from 'react'
import PropTypes from 'prop-types'
import resolveAssetURL from '@/utils/resolveAssetURL'

import {
  Container,
  ContentLabel,
  ContentRight,
  ContentSubtitle,
  ContentText,
  ContentTitle,
  Image,
  ImageWrapper,
  Wrapper
} from './style'
import { Actions } from '@/components/Block/Text/style'

const WineSuggestion = ({
  title = '',
  titleTag = 'h3',
  subtitle = '',
  subtitleTag = 'span',
  label = '',
  text = '',
  image = '',
  alt = '',
  actions = []
}) => {
  return (
    <Container>
      <Wrapper>
        <ImageWrapper>
          <Image loading='lazy' src={ resolveAssetURL(image) } alt={ alt } />
        </ImageWrapper>
        <ContentRight>
          <ContentSubtitle { ...{ as: subtitleTag } }>{subtitle}</ContentSubtitle>
          <ContentTitle { ...{ as: titleTag } }>{title}</ContentTitle>
          <ContentLabel dangerouslySetInnerHTML={ { __html: label } } />
          <ContentText dangerouslySetInnerHTML={ { __html: text } } />
          {
            !!actions.length && (
              <Actions className={ actions.length > 1 ? 'many' : 'one' }>
                {actions.map((action) => action)}
              </Actions>
            )
          }
        </ContentRight>
      </Wrapper>
    </Container>
  )
}

WineSuggestion.propTypes = {
  title: PropTypes.string,
  titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
  subtitle: PropTypes.string,
  subtitleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'span']),
  actions: PropTypes.array,
  label: PropTypes.string,
  text: PropTypes.string.isRequired,
  image: PropTypes.string,
  alt: PropTypes.string
}

export default WineSuggestion
