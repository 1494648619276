import * as React from 'react'

import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'

import { SwiperSlide } from 'swiper/react'
import { GridContainer } from '@/components/Grid'
import Arrows from '@/components/Block/Sliders/atoms/Arrows'

import isExternalLink from '@/utils/isExternalLink'
import { isMobileState } from '@/recoil/layout'

import TagsBlockSliderItem from '@/components/TagsBlockSlider/TagsBlockSliderItem'
import SelectFilter from '@/components/Forms/Inputs/Select'

import {
  ActionWrapper,
  Container,
  Head,
  HeadWrapper,
  LabelIcon,
  LabelLink,
  LabelText,
  Subtitle,
  SwiperContent,
  Text,
  Title,
  Wrapper
} from './style'

import longArrow from '@/images/long-arrow.svg'

const TagsBlockSlider = ({
  subtitle = '',
  title = '',
  text = '',
  tags = [],
  items = [],
  label = 'View all',
  to = '/journal'
}) => {
  const isMobile = useRecoilValue(isMobileState)
  if (items.length === 0) return <></>
  return (
    <Container>
      <GridContainer>
        {title.length > 0 && (
          <Head>
            <HeadWrapper>
              {(subtitle && subtitle.length > 0) && (<Subtitle>{subtitle}</Subtitle>)}
              <Title>{title}</Title>
              {(text && text.length > 0) && (<Text>{text}</Text>)}
            </HeadWrapper>
            {!isMobile &&
              <LabelLink href={ to } target={ isExternalLink(to) ? '_blank' : '_self' }>
                <LabelText>{label}</LabelText>
                <LabelIcon src={ longArrow } />
              </LabelLink>}
          </Head>
        )}
        <Wrapper>
          <SwiperContent
            slidesPerView={ isMobile ? 1.3 : 'auto' }
            spaceBetween={ isMobile ? 20 : 16 }
          >
            {items.map((item, i) => (
              <SwiperSlide key={ i }>
                <TagsBlockSliderItem item={ item } />
              </SwiperSlide>
            ))}

            {!isMobile && (
              <ActionWrapper>
                <Arrows display='inverted' />
              </ActionWrapper>
            )}
          </SwiperContent>
        </Wrapper>
      </GridContainer>
    </Container>
  )
}

TagsBlockSlider.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  tags: PropTypes.arrayOf({
    id: PropTypes.number,
    text: PropTypes.string
  }).isRequired,
  items: PropTypes.arrayOf({
    tag: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.string
  }).isRequired,
  label: PropTypes.string,
  to: PropTypes.string
}

export default TagsBlockSlider
