import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 120rem;
  margin: 2rem auto;
  padding: 0 15px;

  ${mediaMax.xs} {
    width: 100%;
    padding-inline: 4rem;
  }
`

export const Content = styled.div`
  font-size: 2.2rem;
  width: 90%;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  line-height: 140%;
  color: ${({ theme }) => theme.colors.brown};
  letter-spacing: -.044rem;

  ${mediaMax.xs} {
    word-wrap: break-word;
    font-size: 2rem;
    line-height: 150%;
    letter-spacing: -.04rem;
  }
`
