import * as React from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  ContentBottom,
  ContentSubtitle,
  ContentText,
  ContentTitle,
  Wrapper
} from './style'
import { StyledGridContainer } from '@/components/Grid'
import { Actions } from '@/components/Block/Text/style'

const TextCta = ({
  title = '',
  subtitle = '',
  text = '',
  actions = []
}) => {
  return (
    <Container>
      <StyledGridContainer>
        <Wrapper>
          <ContentBottom>
            { !!subtitle && (<ContentSubtitle { ...{ as: 'span' } }>{ subtitle }</ContentSubtitle>) }
            { !!title && (<ContentTitle { ...{ as: 'h3' } }>{ title }</ContentTitle>) }
            { !!text && (<ContentText>{ text }</ContentText>) }
            {
              !!actions.length && (
                <Actions className={ actions.length > 1 ? 'many' : 'one' }>
                  { actions.map((action) => action) }
                </Actions>
              )
            }
          </ContentBottom>
        </Wrapper>
      </StyledGridContainer>
    </Container>
  )
}

TextCta.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string.isRequired,
  actions: PropTypes.array
}

export default TextCta
