import * as React from 'react'
import PropTypes from 'prop-types'

import {
  Content,
  Container
} from './style'

const ArticleSummary = ({
  text = ''
}) => {
  return (
    <Container>
      <Content>
        { text }
      </Content>
    </Container>
  )
}

ArticleSummary.propTypes = {
  text: PropTypes.string
}

export default ArticleSummary
