import styled from '@emotion/styled'
import Link from '@/components/LocalizedGatsbyLink'
import { mediaMax } from '@/styles/mixins'

export const Image = styled.img`
  width: 100%;
  height: 100%;
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 22rem;

  ${mediaMax.sm} {
    height: 17.4rem;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
`

export const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;

  ${mediaMax.sm} {
    margin-right: 0;
  }
`

export const ContentTag = styled.span`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.4rem;
  font-weight: 40;
  color: ${({ theme }) => theme.colors.brown};
  text-transform: uppercase;
  letter-spacing: .1rem;

  ${mediaMax.sm} {
    font-size: 1.2rem;
  }
`

export const ContentTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 2.4rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.brown};
  line-height: 1.2em;
  letter-spacing: -0.03rem;
  margin-top: 2rem;

  ${mediaMax.sm} {
    margin-top: 1.6rem;
  }
`
